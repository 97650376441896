import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShowLoader } from '../../redux/loaderSlice';
import { message } from 'antd';
import { GetNotifications } from '../../apicalls/appointments';
import { useNavigate } from 'react-router-dom';
import styles from "./Notification.module.css"
import moment from 'moment';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const [sortNotes, setSortNotes] = useState('New');
  const user = JSON.parse(localStorage.getItem('user'));

  const onSortHandle = (e) => {
    setSortNotes(e.target.value);
  };

  const sortByTime = (a, b) => {
    if (user.role === "user") {
      const dateA = moment(a.updateTime, 'DD-MM-YYYY hh:mm A');
      const dateB = moment(b.updateTime, 'DD-MM-YYYY hh:mm A');

      if (sortNotes === 'New') {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    } else {
      const dateA = moment(a.bookedOn, 'DD-MM-YYYY hh:mm A');
      const dateB = moment(b.bookedOn, 'DD-MM-YYYY hh:mm A');

      if (sortNotes === 'New') {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    }
  };

  const checkNotifications = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetNotifications(user.id, user.role);
      const noties = response.data;

      if (response.success) {
        const sortedNoties = [...noties].sort(sortByTime);
        setNotifications(sortedNoties);
      } else {
        message.error(response.message);
      }

      dispatch(ShowLoader(false));
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  };

  useEffect(() => {
    checkNotifications();
  }, [sortNotes]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.notifmaincontainer}>
        <h2 style={{textAlign:"center"}} >Noties</h2>
        <div className={styles.sortcont} >
          <label htmlFor="sort">
            <p>Sort</p>
          </label>
          <select  id="sortNote" value={sortNotes} onChange={onSortHandle}>
            <option value="New">Newest to Oldest</option>
            <option value="old">Oldest to Newest</option>
          </select>
        </div>
       
      </div>

      {user.role === "user" && notifications.map((notis) => {
        return (
          <div key={notis.id}>
            {notis.updateTime}: Your appointment with {notis.doctorName} has been {notis.status}
          </div>
        );
      })}
      {user.role !== "user" && notifications.map((notis) => {
        return (
          <div className={styles.docnotifiCont}>
          <span className={styles.notifictitle} key={notis.id}>
            <span className={styles.title}>{notis.userName}</span> has requested for an appointment on <span className={styles.title}>{notis.date}</span> at <span className={styles.title}>{notis.slot}</span>, go to my appointments page to reject or approve.
            <p className={styles.bookedtime} >{notis.bookedOn}</p>
          </span>
          
          </div>
         
        );
      })}

    </div>
  );
}

export default Notifications;
