import About from "./about/About"
import Footer from "./footer/Footer"
import Header from "./header/Header"
import Mission from "./mission/Mission"
import Talk from "./talk/Talk"
import Team from "./team/Team"
import Tech from "./tech/Tech"
import Webinar from "./webinar/Webinar"

const Home = () => {
  return (
    <>
        <Header   title={"OnlyMind"} descr={"Onlymind: Empowering Your Emotional Wellbeing. Find Inner Peace and Harmony with Mindfulness, Therapy, and Self-Care. Discover Guided Meditations, Counseling, and Resources for Anxiety, Depression, Trauma, and More. Start Your Journey to a Healthier, Happier You Today."} imgurl={"../assets/herosvg.svg"} btntitle={"Get Started"}/>
        <About/>
        <Webinar/>
        <Team/>
        <Talk/>
        <Mission/>
    </>
  )
}

export default Home