import React, { useState } from "react";

import './header.css'
import ComingSoon from '../../comingSoon/ComingSoon';


const Header = ({title,descr,imgurl,btntitle}) => {
  const [isComingSoonOpen, setComingSoonOpen] = useState(false);
  
  const openComingSoonModal = () => {
    setComingSoonOpen(true);
  };

  const closeComingSoonModal = () => {
    setComingSoonOpen(false);
  };
  return (
    <header className="header">
      <div className="main_container header-container">
        <div className="hero-left">
          <div className="hero-title">
            <h1 className='herotitle'>{title}</h1>
          </div>
          <div className="des">
            <p >
            {descr}
            </p>
          </div>
          
        </div>
        <div className="hero-right"><img src={imgurl} alt="" style={{ width: "100%", height: "100%" }}  /></div>
        {isComingSoonOpen && <ComingSoon closeModal={closeComingSoonModal} />}
      </div>
    </header>
  );
}

export default Header