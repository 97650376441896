import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Menu.css";
import { IconContext } from "react-icons";
import ComingSoon from "../Pages/comingSoon/ComingSoon";

const Menu = () => {
  const [sidebar, setSidebar] = useState(false);
  const [isComingSoonOpen, setComingSoonOpen] = useState(false);

  const openComingSoonModal = () => {
    setComingSoonOpen(true);
  };

  const closeComingSoonModal = () => {
    setComingSoonOpen(false);
  };

  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSidebar} size={25} />
          </Link>
        </div>
        <div className={sidebar ? "nav-menu active" : "nav-menu"}>
          <div className="nav-menu-items" onClick={showSidebar}>
            <div className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </div>

            <li className="menu_li">
              <Link to="/">Home</Link>
            </li>
            <li className="menu_li">
              <Link to="/services">Our Services</Link>
            </li>
            <li className="menu_li">
              <Link to="/about">About Us</Link>
            </li>
            <li className="menu_li">
              <Link to="/contact">Contact Us</Link>
            </li>

            <Link to="" className="menu_item">
              <button className="buttonham" onClick={openComingSoonModal}>
                Login
              </button>
            </Link>

            <Link to="" className="menu_item">
              <button
                onClick={openComingSoonModal}
                className="buttonham "
                style={{ background: "#7F39FB", color: "white" }}
              >
                SignUp
              </button>
            </Link>
          </div>
        </div>
      </IconContext.Provider>
      {isComingSoonOpen && <ComingSoon closeModal={closeComingSoonModal} />}
    </>
  );
};

export default Menu;
