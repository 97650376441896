import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ShowLoader } from '../../redux/loaderSlice';
import { GetDoctorById } from '../../apicalls/doctors';
import { BookAppointmentStore, GetDoctorAppointsOnDate } from '../../apicalls/appointments';
import { message } from 'antd';
import styles from './BookAppointment.module.css'
import moment from 'moment/moment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function BookAppointment() {
  const [doctor, setDoctor] = useState({});
  const [consultMode, setConsultMode] =useState("");
  const { id } = useParams();
  const [date, setDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [bookedSlots, setBookedSlots] = useState([]);
  const [problem, setProblem] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const getData = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetDoctorById(id);
      if (response.success) {
        setDoctor(response.data);
      } else {
        message.error("Incorrect URL");
      }
      dispatch(ShowLoader(false));
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  }

  const getSlotsData = () => {
    const day = moment(date).format("dddd");
    if (!doctor.days.includes(day)) {
      return <h3>Doctor is not available on {moment(date).format("DD-MM-YYYY")}</h3>
    }

    let startTime = moment(doctor.startTime, "hh:mm A");
    let endTime = moment(doctor.endTime, "hh:mm A");
    let slotDuration = 60; //in minutes
    const slots = [];
    while (startTime < endTime) {
      slots.push(startTime.format("hh:mm A"));
      startTime.add(slotDuration, "minutes");
    }
    return slots.map((slot) => {
      const isBooked = bookedSlots?.find(
        (bookedSlot) => bookedSlot.slot === slot && bookedSlot.status !=="cancelled"
      );
      return <div onClick={() => setSelectedSlot(slot)}
        style={{
          border: selectedSlot === slot ? "3px solid green" : "1px solid gray",
          backgroundColor: isBooked ? "gray" : "white",
          pointerEvents: isBooked ? "none" : "auto",
          cursor: isBooked ? "not-allowed" : "pointer",
          color: isBooked ? "white" : "black",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          fontWeight: "bold",
        }}>
        <span>
          {moment(slot, "hh:mm A").format("hh:mm A")} - {" "} {moment(slot, "hh:mm A").add(slotDuration, "minutes").format("hh:mm A")}
        </span>
      </div>
    })
  }

  const onBookAppointment = async () => {
    try {
      dispatch(ShowLoader(true));
      const payload = {
        doctorId: doctor.userId,
        userId: JSON.parse(localStorage.getItem("user")).id,
        date,
        slot: selectedSlot,
        doctorName: doctor.name,
        doctorEmail: doctor.email,
        userName: JSON.parse(localStorage.getItem("user")).name,
        userEmail: JSON.parse(localStorage.getItem("user")).email,
        bookedOn: moment().format("DD-MM-YYYY hh:mm A"),
        consultMode: consultMode,
        problem: problem,
        status: "pending",
      };
      const response = await BookAppointmentStore(payload);
      if (response.success) {
        message.success(response.message);
        navigate("/profile");
      } else {
        message.error(response.message);
      }
      dispatch(ShowLoader(false));
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  }

  const getBookedSlots = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetDoctorAppointsOnDate(id, date);
      dispatch(ShowLoader(false));
      if (response.success) {
        console.log(response.data);
        setBookedSlots(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  }

  const handleConsultMode= (e)=>{
    setConsultMode(e.target.value);
  }

  useEffect(() => {
    getData();
  }, [id])

  useEffect(() => {
    if (date) {
      getBookedSlots();
    }
  }, [date]);

  return (
    <>
      <div className={styles.mainContainer}>

        {/* firstdiv */}
        <div className={styles.firstdiv} >
          <div className={styles.left}>
            <div className={styles.imgdiv} >
              <img src="/assets/avatar.png" alt="" />
            </div>
            <div className={styles.item}>
              <h2>{doctor.name}</h2>
            </div>
            <div className={styles.item}>

              <p className={styles.speciality} >{doctor.speciality}</p>
            </div>
            <div className={styles.item}>
              <BusinessCenterIcon className={styles.icon} />
              <p>{doctor.experience} years</p>
            </div>
            <div className={styles.item}>
              <EmailIcon className={styles.icon} />
              <p>{doctor.email}</p>
            </div>
            <div className={styles.item}>
              <PhoneIcon className={styles.icon} />
              <p>{doctor.phoneNumber}</p>
            </div>
            <div className={styles.item}>
              <CurrencyRupeeIcon className={styles.icon} />
              <p>{doctor.fees}</p>
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <span style={{ fontWeight: "bold" }}> Days Available:</span> &nbsp; {doctor.days ? doctor.days.join(', ') : ''}
            </div>
            <div className={styles.DatePicker} >
              <input type='date' value={date} onChange={(e) => setDate(e.target.value)} min={moment().format("YYYY-MM-DD")} />
            </div>
            <div className={styles.consultation} >
              <p style={{ fontWeight: "bold", marginBottom: "0.6rem" }}>Consultation Mode</p>
              <input type="radio" id="physical" name="media" value="physical" onChange={handleConsultMode}/>
              <label for="physical" style={{ fontWeight: "normal", marginLeft: "10px" }}>Physical</label>
              <input type="radio" id="video" name="media" value="video" onChange={handleConsultMode}/>
              <label for="video" style={{ fontWeight: "normal", marginLeft: "10px" }}>Video</label>
            </div>
          </div>
          <div className={styles.center} >
             <textarea
                placeholder='Enter your problem here'
                rows="10" col="20" className={styles.problemarea}   onChange={(e)=> setProblem(e.target.value)}
              ></textarea>
          </div>
          
        </div>

        {/* seconddiv */}
        <div className={styles.seconddiv} >
          {selectedSlot &&
            (<div>
              
              <div className={styles.bottomleft}>
                <button className={styles.bookbtn} onClick={onBookAppointment}>Book Appointment</button>
                <button className={styles.btnOutline} onClick={() => navigate("/doctordetails")}>
                  Cancel
                </button><br></br>
              </div>
            </div>)}

          <div className={styles.timeselectContainer}>
            <button className={styles.slotbtn} >
              {date ? "Select Slot" : "Choose Date First"}
            </button>
            <div className={styles.timeContainer} >
              {date && getSlotsData()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookAppointment