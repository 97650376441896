import { collection, addDoc, getDocs, updateDoc, query, where, setDoc, doc } from 'firebase/firestore';
import firestoreDatabase from '../firebaseConfig';
import CryptoJS from 'crypto-js';

export const CreateUser = async (payload) => {
  try {
    const usersRef = collection(firestoreDatabase, 'users');
    // Check if user already exists using email
    const qry = query(usersRef, where("email", "==", payload.email));
    const querySnapshot = await getDocs(qry);
    if (querySnapshot.size > 0) {
      return {
        success: false,
        message: 'User already exists'
      };
    }
    // HashPassword
    const hashedPassword = CryptoJS.AES.encrypt(
      payload.password,
      "wewintogether28"
    ).toString();
    payload.password = hashedPassword;

    // Adding user to Firestore
    const docRef = await addDoc(usersRef, payload);
    await updateDoc(docRef, {
      id: docRef.id
    });
    console.log("Data successfully sent to Firestore", docRef.id);
    return {
      success: true,
      message: "Registered successfully",
    };
  } catch (error) {
    console.log("catch:", error.message);
    return {
      success: false,
      message: "Try Again",
    };
  }
};

export const LoginUser = async (payload) => {
  try {
    const usersRef = collection(firestoreDatabase, 'users');
    const qry = query(usersRef, where("email", "==", payload.email));
    const userSnapshot = await getDocs(qry);
    if (userSnapshot.size === 0) {
      return {
        success: false,
        message: "User Doesn't Exist"
      };
    }
    const user = userSnapshot.docs[0].data();
    const bytes = CryptoJS.AES.decrypt(user.password, "wewintogether28");
    const originalPassword = bytes.toString(CryptoJS.enc.Utf8);
    if (originalPassword !== payload.password) {
      return {
        success: false,
        message: "Incorrect Password"
      };
    } else {
      return {
        success: true,
        message: "User logged In Successfully",
        data: user,
      };
    }
  } catch (error) {
    return {
      success: true,
      message: "Try Again",
    };
  }
}

export const GetAllUsers = async () => {
  try {
    const users = await getDocs(collection(firestoreDatabase, "users"));
    return {
      success: true,
      data: users.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      })
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  };
}

export const GetUserById = async (id) => {
  try {
    const usersRef = collection(firestoreDatabase, 'users');
    const qry = query(usersRef, where("id", "==", id));
    const userSnapshot = await getDocs(qry);
    const user = userSnapshot.docs[0].data();
    return {
      success: true,
      data: user,
    };
  } catch (error) {
    return {
      success: true,
      message: "Try Again",
    };
  }
}

export const UpdateUser = async (payload) => {
  try {
    await setDoc(doc(firestoreDatabase, "users", payload.id), payload);
    return {
      success: true,
      message: "Updated successfully",
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  };
}