import "./Contact.scss";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [queryformdata, setData] = useState({});
  const form = useRef();
  const serviceid = process.env.REACT_APP_SERVICE_ID;
  const templateid = process.env.REACT_APP_TEMPLATE_ID;
  const userid = process.env.REACT_APP_USER_ID;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hmco71v",
        "template_cls2tj8",
        form.current,
        "N8VxW5tqohs_wPwbR"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="container">
      <div className="contactcont">
        <div className="left">
          <div className="imgcont">
            <img src="../assets/contact.svg" alt="conatctsvg" />
          </div>
        </div>
        <div className="right">
          <div className="first">
            <div className="contact_items">
              <EmailIcon style={{ color: "#9D3BEA", fontSize: "2rem" }} />
              <p>hello@prescientai.in</p>
            </div>
            <div className="contact_items">
              <PhoneIcon style={{ color: "#9D3BEA", fontSize: "2rem" }} />
              <p>+91 9729712499</p>
            </div>
            <div className="contact_items" >
              <LocationOnIcon style={{ color: "#9D3BEA", fontSize: "2rem" }} />
              <p>Chandigarh,India</p>
            </div>
          </div>
          <button className="feedbutton">Feedback/Query</button>

          {/* Second */}
          <div className="second">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-row namefield">
                <label for="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={queryformdata.name}
                  name="from_name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="bigrow">
                <div className="form-row">
                  <label for="mobile-number">Mobile Number:</label>
                  <input
                    type="tel"
                    placeholder="9876543210"
                    value={queryformdata.mobile}
                    name="from_contact"
                    maxlength="10"
                    id="mobile-number"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-row">
                  <label for="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="from_email"
                    placeholder="abc123@gmail.com"
                    value={queryformdata.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <label for="query">Query/Feedback:</label>
                <textarea
                  id="query"
                  name="message"
                  value={queryformdata.query}
                  onChange={handleChange}
                  className="query"
                  required={true}
                ></textarea>
              </div>
              <div className="submit-button">
                <button>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
