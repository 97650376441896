import "./DropdownMenu.css";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from "@mui/icons-material/Logout";

const DropdownMenu = ({handleLogOut}) => {
  return (
    <div className="dropdown-menu">
      <Link to="/myappointments" className="dropdown_item menu-item">
        <span className="icon-button">
          <EventNoteIcon />
        </span>
        My Appointments
      </Link>
      <Link to="#" className="dropdown_item menu-item">
        <span className="icon-button">
          <Diversity3Icon />
        </span>
        My Sessions
      </Link>
      <Link to="/notifications" className="dropdown_item menu-item">
        <span className="icon-button">
          <NotificationsIcon />
        </span>
        Notifications
      </Link>
      <Link to="/editprofile" className="dropdown_item menu-item">
        <span className="icon-button">
          <AccountCircleIcon />
        </span>
        Edit Profile
      </Link>
      <Link to="#" className="dropdown_item menu-item" onClick={handleLogOut}>
        <span className="icon-button">
          <LogoutIcon />
        </span>
        <div
          className="dropdown_logout"
        >
          Logout
        </div>
      </Link>
    </div>
  );
};

export default DropdownMenu;
