import { useEffect, useState } from 'react';
import styles from './DoctorForm.module.css';
import { useDispatch } from 'react-redux';
import { ShowLoader } from '../../redux/loaderSlice';
import { AddDoctor, GetDoctorById, UpdateDoctor } from '../../apicalls/doctors';
import { message } from 'antd';
import { useNavigate } from 'react-router';

const initialFormState = {
  name: '',
  email: '',
  phoneNumber: '',
  qualification: '',
  languagespoken: '',
  speciality: '',
  experience: '',
  fees: '',
  startTime: '',
  endTime: '',
  days: [],
  specializations: [],
};

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const DoctorForm = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [alreadyDoctorData, setDoctorData] = useState(formData);
  const [errors, setErrors] = useState({});
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [alreadyApproved, setAlreadyApproved] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'specializations') {
      const specializationsArr = value
        .split(',')
        .map((specialization) => specialization.trim());
      if (alreadyApplied) {
        setDoctorData((prevData) => ({
          ...prevData,
          [name]: specializationsArr,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: specializationsArr,
        }));
        console.log('Hello PrescientAI');
        console.log(formData);
      }
    }
    if (alreadyApplied) {
      setDoctorData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if (alreadyApplied) {
        setDoctorData((prevData) => ({
          ...prevData,
          days: [...prevData.days, value],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          days: [...prevData.days, value],
        }));
      }
    } else {
      if (alreadyApplied) {
        setDoctorData((prevData) => ({
          ...prevData,
          days: prevData.days.filter((day) => day !== value),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          days: prevData.days.filter((day) => day !== value),
        }));
      }
    }
  };

  const validateForm = () => {
    const { name, email, phoneNumber, speciality, experience, fees } = formData;
    const errors = {};

    if (!name) {
      errors.name = 'Name is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!isValidPhoneNumber(phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }
    if (!speciality) {
      errors.speciality = 'Speciality is required';
    }
    if (!experience) {
      errors.experience = 'Experience is required';
    }
    if (!fees) {
      errors.fees = 'Fees is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(ShowLoader(true));
      let payload = null;
      let response = null;
      if (alreadyApproved) {
        payload = {
          ...alreadyDoctorData,
          userId: JSON.parse(localStorage.getItem('user')).id,
        };
        response = await UpdateDoctor(payload);
        const { userId, name, email } = payload;
        const sanitizedPayload = {
          id: userId,
          email,
          name,
          password: '****',
          role: 'doctor',
        };
        localStorage.setItem('user', JSON.stringify(sanitizedPayload));
      } else {
        payload = {
          ...formData,
          userId: JSON.parse(localStorage.getItem('user')).id,
          status: 'pending',
        };
        response = await AddDoctor(payload);
      }
      if (response) {
        message.success(response.message);
      } else {
        message.error(response.message);
      }
      dispatch(ShowLoader(false));
      if (!alreadyApproved) {
        if (validateForm()) {
          setFormData(initialFormState);
        }
        navigate('/myappointments');
      }
    } catch (error) {
      dispatch(ShowLoader(false));
      message.error(error.message);
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  const checkIfAlreadyApplied = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetDoctorById(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (response.success) {
        setAlreadyApplied(true);
        setDoctorData(response.data);
        if (user.role === 'doctor') {
          setAlreadyApproved(true);
        }
      }
      dispatch(ShowLoader(false));
    } catch (error) {
      dispatch(ShowLoader(false));
      message.error(error.message);
    }
  };

  useEffect(() => {
    checkIfAlreadyApplied();
  }, []);

  return (
    <>
      {(!alreadyApplied || alreadyApproved) && (
        <>
          <h1 style={{ paddingTop: '40px', paddingLeft: '20px' }}>
            {alreadyApplied
              ? 'Update your information'
              : 'Add Details to Contribute as Doctor'}
          </h1>
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            {/* ######################### NameField Div ####################### */}
            <h2 className={styles.heading}>Personal Details</h2>
            <div className={styles.fieldwrapper}>
              <div>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={
                    alreadyApproved ? alreadyDoctorData.name : formData.name
                  }
                  onChange={handleChange}
                  required
                  className={errors.name ? styles.error : ''}
                />
                {errors.name && (
                  <span className={styles.errorMessage}>{errors.name}</span>
                )}
              </div>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={
                    alreadyApproved ? alreadyDoctorData.email : formData.email
                  }
                  onChange={handleChange}
                  required
                  className={errors.email ? styles.error : ''}
                />
                {errors.email && (
                  <span className={styles.errorMessage}>{errors.email}</span>
                )}
              </div>
              <div>
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={
                    alreadyApproved
                      ? alreadyDoctorData.phoneNumber
                      : formData.phoneNumber
                  }
                  onChange={handleChange}
                  required
                  className={errors.phoneNumber ? styles.error : ''}
                />
                {errors.phoneNumber && (
                  <span className={styles.errorMessage}>
                    {errors.phoneNumber}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="qualification">Qualification:</label>
                <input
                  type="text"
                  id="qualification"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="language">Language Spoken:</label>
                <input
                  type="text"
                  id="language"
                  name="languagespoken"
                  value={formData.languagespoken}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="specializations">specializations:</label>
                <textarea
                  name="specializations"
                  id="specializations"
                  cols="30"
                  rows="3"
                  style={{ maxWidth: '100%', maxHeight: '100px' }}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>

            {/* ######################### Speciality Div ####################### */}
            <h2 className={styles.heading}>Professional Experience</h2>
            <div className={styles.fieldwrapper}>
              <div>
                <label htmlFor="speciality">Speciality:</label>
                <select
                  id="speciality"
                  name="speciality"
                  value={
                    alreadyApproved
                      ? alreadyDoctorData.speciality
                      : formData.speciality
                  }
                  onChange={handleChange}
                  required
                  className={styles.customselect}
                >
                  <option
                    className={styles.optionsele}
                    value="Disorders analyst"
                  >
                    Disorders analyst
                  </option>
                  <option className={styles.optionsele} value="Psychiatrist">
                    Psychiatrist
                  </option>
                  <option className={styles.optionsele} value="Physician">
                    Physician
                  </option>
                  <option className={styles.optionsele} value="Psychotherapist">
                    Psychotherapist
                  </option>
                  <option className={styles.optionsele} value="Psychologist">
                    Psychologist
                  </option>
                </select>
                {errors.speciality && (
                  <span className={styles.errorMessage}>
                    {errors.speciality}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="experience">Experience:</label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  value={
                    alreadyApproved
                      ? alreadyDoctorData.experience
                      : formData.experience
                  }
                  onChange={handleChange}
                  required
                  className={errors.experience ? styles.error : ''}
                />
                {errors.experience && (
                  <span className={styles.errorMessage}>
                    {errors.experience}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="fees">Fees:</label>
                <input
                  type="text"
                  id="fees"
                  name="fees"
                  value={
                    alreadyApproved ? alreadyDoctorData.fees : formData.fees
                  }
                  onChange={handleChange}
                  required
                  className={errors.fees ? styles.error : ''}
                />
                {errors.fees && (
                  <span className={styles.errorMessage}>{errors.fees}</span>
                )}
              </div>
            </div>

            {/* ######################### Time Div ####################### */}
            <h2 className={styles.heading}>Add Your Availabilty Time</h2>
            <div className={styles.timediv}>
              <div>
                <label htmlFor="startTime">Start Time:</label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  value={
                    alreadyApproved
                      ? alreadyDoctorData.startTime
                      : formData.startTime
                  }
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="endTime">End Time:</label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  value={
                    alreadyApproved
                      ? alreadyDoctorData.endTime
                      : formData.endTime
                  }
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* ######################### Day Selection Div     ####################### */}
            <h2 className={styles.heading}>Select Days:</h2>
            <div className={styles.checkboxGroup}>
              {daysOfWeek.map((day) => (
                <label style={{ fontWeight: 'normal' }} key={day}>
                  <input
                    type="checkbox"
                    name="days"
                    value={day}
                    checked={
                      alreadyApproved
                        ? alreadyDoctorData.days.includes(day)
                        : formData.days.includes(day)
                    }
                    onChange={handleCheckboxChange}
                  />
                  {day}
                </label>
              ))}
            </div>
            <div className={styles.buttondiv}>
              <button type="submit" className={styles.submitButton}>
                {alreadyApplied ? 'Update' : 'Apply'}
              </button>
            </div>
          </form>
        </>
      )}
      {alreadyApplied && !alreadyApproved && (
        <h1>
          You've already applied for the Doctor, Please wait for the approval
        </h1>
      )}
    </>
  );
};
export default DoctorForm;
