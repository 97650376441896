

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import DropdownMenu from "./DropdownMenu";

import ComingSoon from "../Pages/comingSoon/ComingSoon";
import HamburgerMenu from "./HamburgerMenu";
import Menu from "./Menu";


const Navbar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate =  useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("user");
    setOpen(false);
    setTimeout(()=>{
      navigate('/login');
    },200)
  };
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => {
    setOpen(!open);
  };


  const [isComingSoonOpen, setComingSoonOpen] = useState(false);
  
  const openComingSoonModal = () => {
    setComingSoonOpen(true);
  };

  const closeComingSoonModal = () => {
    setComingSoonOpen(false);
  };

  return (
    <nav>
      <div className="main_container nav__container">
        <Link to="/" className="nav__logo">
          <img src="/assets/logo2.png" alt="logo" width={70} />
        </Link>
        <ul className="nav__menu">
          <li className="nav-item">
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/services">Our Services</Link>
          </li>
          <li className="nav-item">
            <Link to="/about">About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">Contact Us</Link>
          </li>
          {user && user.role !=="doctor" && (<li className="nav-item">
            <Link to="/applyfordoctor">Apply as doctor</Link>
          </li>)}
        </ul>
        <div className="nav-btns">
          {user ? (
            <div
            className="nav_user_name"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                alignItems: "center",
                
              }}
            >
              <div  >
                {user.name}
              </div>
              <div className="nav_dropdownmenu_div"><DropdownMenu handleLogOut={handleLogOut} /></div>
              
            </div>
          ) : (
            <>
              {/* <Link to="/login"> */}
              <Link to="">
                <button className="button-23  login_btn"  onClick={openComingSoonModal} >Login</button>
              </Link>
              {/* <Link to="/register"> */}
              <Link to="">
                <button
                onClick={openComingSoonModal}
                  className="button-23 SolidButton"
                  style={{ background: "#7F39FB", color: "white" }}
                >
                  SignUp
                </button>
              </Link>
            </>
          )}
        </div>

        <div className="ham_menu">
        <Menu/>
        </div>
      </div>
      {isComingSoonOpen && <ComingSoon closeModal={closeComingSoonModal} />}
    </nav>
  );
};

export default Navbar;