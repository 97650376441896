import Header from '../homePage/header/Header'
import AboutService from './AboutService/AboutService'
import styles from './Services.module.css'

const Services = () => {
  return (
    <div>
      <Header title={"Our Services"} descr={"Specialized physical consultancy for doctors and patients. Holistic wellness approach. Yoga and meditation sessions offered. Transformative guidance for optimal health." } btntitle={"Explore"} imgurl={"../assets/servicesimg1.svg"}/>
      <AboutService/>
    </div>
  )
}

export default Services
