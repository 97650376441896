import React from "react";
import styles from "./ComingSoon.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import NearMeIcon from '@mui/icons-material/NearMe';

const ComingSoon = ({ closeModal }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal_content}>
        <span className={styles.close} onClick={closeModal}>
          <CloseIcon />
        </span>
        <img src="/assets/logo2.png" alt="" className={styles.logo} />

        <div className={styles.div1}>
          <h1 className={styles.title}>Coming Soon</h1>
          <p className={styles.txt}>
            We're diligently Working On Creating Something fantastic. <br />{" "}
            Well, Be Here soon.
          </p>
          <Link to="/contact">
            <button className={styles.button_23} onClick={closeModal}>
              For any query click here 
              <NearMeIcon className={styles.icon}/>
            </button>
          </Link>
        </div>
      </div>
    </div>
    
  );
};

export default ComingSoon;
