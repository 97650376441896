import React from "react";
import "./webinar.css";

import "./webinar.css";

const Webinar = () => {
  return (
    <section className="event-sec">
      <div className="main_container event-container">
        <h2 className="event-title">Webinars</h2>
        <div className="event-left">
          <div className="event_flex">
            <div className="event_div1">
              <span>Popular Event </span>
              <img className="event-arrow" src="/assets/arrow.svg" width={1} />
              <div className="event-box">
                <div className="ev-card-left">
                  <span className="ev-card-title">Yoga & Meditation Session</span>
                  <span className="ev-card-details">
                  Discover inner peace and harmony at our popular Yoga and Meditation session. Join us for a transformative experience, promoting physical, mental, and spiritual well-being. Namaste.
                  </span>
                  <button className="button-ev" role="button">
                    Join Now
                  </button>
                </div>
                <div className="ev-card-right">
                  <img src="/assets/Ellipse 1.svg" alt="" />
                  {/* <img src="/assets/Ellipse 2.svg" className="ev-pic2" alt="" /> */}
                </div>
              </div>
            </div>
            <div className="event-right">
              <img className="ev-img" src="/assets/eventimg.svg" alt="" />
            </div>
          </div>
          <span className="popular-ev-heading">Upcoming Events<img className="event-arrow" src="/assets/arrow.svg" width={1} />
 </span>
          
          <div className="popular-ev">
            <div className="event-box-sm">
              <div className="ev-card-left popular-card">
                <span className="ev-card-title ev-gradient">15 Aug 2023</span>
                <span className="ev-card-details popular-details">
                Discover serenity, find balance. Join our transformative Meditation Retreat, explore mindfulness practices for inner peace.
                </span>
                <button className="button-ev" role="button">
                  Join Now
                </button>
              </div>
              <div className="ev-card-right popular-card-img">
                <img src="/assets/profimg1.svg" alt="" />
              </div>
            </div>

            <div className="event-box-sm">
              <div className="ev-card-left popular-card">
                <span className="ev-card-title ev-gradient">22 Oct 2023</span>
                <span className="ev-card-details popular-details">
                Embrace well-being. Attend our Mental Health Workshop, learn coping strategies, stress reduction, and emotional resilience.
                </span>
                <button className="button-ev" role="button">
                  Join Now
                </button>
              </div>
              <div className="ev-card-right popular-card-img">
                <img src="/assets/profimg3.svg" alt="" />
              </div>
            </div>

            <div className="event-box-sm">
              <div className="ev-card-left popular-card">
                <span className="ev-card-title ev-gradient">10 Nov 2023</span>
                <span className="ev-card-details popular-details">
                Revitalize your body. Join the Physical Health Expo for fitness demos, nutrition tips, and health screenings. Elevate well-being.
                </span>
                <button className="button-ev" role="button">
                  Join Now
                </button>
              </div>
              <div className="ev-card-right popular-card-img">
                <img src="/assets/profimg2.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Webinar;
