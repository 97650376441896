import React from "react";
import "./aboutus.css";
import AbtTeamCards from './abtTeamCards';





const AboutUs = () => {
  return (
    <section className="aboutus-sec">
      <div className="main_container aboutus_container">
        <div className="about-div1">
          <div className="abt-missionsvg">
            <img src="/assets/missionsvg.svg" alt="" />
          </div>
          <div className="abt-txt">
            <h1>Our Mission</h1>
            <p>
            Our mission is to provide accessible and effective resources to help you achieve inner peace and harmony. We believe in the power of mindfulness, self-care, and therapy to improve emotional wellbeing.
            <br />
            <br />
Our platform offers a range of tools and services to help you on your journey. From guided meditations and counseling sessions to resources for dealing with anxiety, depression, trauma, eating disorders, insomnia, and personality disorders, we have everything you need to prioritize your emotional wellbeing.
            </p>
          </div>
        </div>
        <div className="about-div1 about_flex">
          <div className="abt-txt">
            <h1>Our Story</h1>
            <p>
            OnlyMind: Revolutionizing Mental Health Care. Experience our cutting-edge platform that utilizes AI, ML, and NLP to create a safe haven for mental well-being. Join our diverse community and access personalized support through expert-guided therapy, mindfulness practices, and skill development programs. 
            <br />
            <br />
            We prioritize privacy and security, providing a secure space for personal growth. Discover resilience, connection, and empowerment as OnlyMind empowers individuals on their unique journey towards solace, understanding, and a brighter, healthier future. Start your transformation today with OnlyMind.
            </p>
          </div>
          <div className="abt-missionsvg">
            <img src="/assets/aboutstory.svg" alt="" />
          </div>
        </div>
        <div className="about-div3">
          <h1>Our Values</h1>
          <div className="abt-value-cards">
            <div className="abt-value-card">
              <h3>Accessibility and Empowerment</h3>
              <p>At Onlymind, we believe mental health support should be accessible to all. We break down barriers and empower individuals to take control of their well-being.</p>
            </div>
            <div className="abt-value-card">
              <h3>Privacy, Security, and Personalization</h3>
              <p>We prioritize privacy and security, handling data with utmost care. Our personalized approach recognizes unique mental health needs, providing tailored support for lasting impact.
              </p>
            </div>            
            <div className="abt-value-card">
              <h3>Commitment to Quality and Evidence-Based Approach</h3>
              <p>Quality matters. Our evidence-based practices deliver effective mental health support. We stay updated with research to ensure our resources align with industry standards for maximum impact.</p>
            </div>
          </div>
        </div>
        {/* <div className="about-div4">
          <h1>Our Team</h1>
          <div className="abt-team-card">
            <AbtTeamCards/>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUs;
