
const aboutData = [
  {
    id: "1",
    img:"../assets/team.jpg",
    name: "Lorem ipsum1",
    designation: "Vivamus elementum",
    about:"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo"

  },
  {
    id: "2",
    img:"/assets/team.jpg",
    name: "Lorem ipsum2",
    designation: "Vivamus elementum",
    about:"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo"

  },
  {
    id: "3",
    img:"/assets/team.jpg",
    name: "Lorem ipsum",
    designation: "Vivamus elementum",
    about:"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo"
  },
  {
    id: "4",
    img:"/assets/team.jpg",
    name: "Lorem ipsum",
    designation: "Vivamus elementum",
    about:"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo"
  },
];

export default aboutData;

  