import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ShowLoader } from '../../redux/loaderSlice';
import { Table, message } from 'antd';
import { GetAllDoctors, UpdateDoctor } from '../../apicalls/doctors';

function DoctorsList() {
    const [doctors, setDoctors]= useState([]);
    const dispatch= useDispatch();
    const getData= async() => {
        try {
            dispatch(ShowLoader(true));
            const response= await GetAllDoctors();
            dispatch(ShowLoader(false));
            if (response.success) {
                setDoctors(response.data);
            } else {
                message.error(response.message)
            }
        } catch (error) {
            dispatch(ShowLoader(false));
            message.error(error.message);
        }
    }
    const ApproveButtonStyle ={
        backgroundColor:'green',
        color:'white',
        padding:'5px 10px',
        borderRadius:'10px',
       width:'80px',
       maxWidth:'100px',
       marginLeft:'10px',
       cursor:'pointer',
       fontWeight:'bold'
    }
    const RejectButtonStyle ={
        backgroundColor:'red',
        color:'white',
        padding:'5px 10px',
        borderRadius:'10px',
       width:'80px',
       maxWidth:'100px',
       cursor:'pointer',
       fontWeight:'bold'
    }
    const BlockButtonStyle ={
        backgroundColor:'brown',
        color:'white',
        padding:'5px 10px',
        borderRadius:'10px',
       width:'80px',
       maxWidth:'100px',
       cursor:'pointer',
       fontWeight:'bold'
    }
   
    const columns= [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Phone",
            dataIndex: "phoneNumber"
        },
        {
            title: "Speciality",
            dataIndex: "speciality"
        },
        {
            title: "Experience",
            dataIndex: "experience"
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record)=> {
                return text.toUpperCase()
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (text,record)=> {
                if (record.status === "pending") {
                    return (
                        <div>
                            <span style={RejectButtonStyle} onClick={()=> changeStatus({...record, status:"rejected"})}>Reject</span>
                            <span style={ApproveButtonStyle}  onClick={()=> changeStatus({...record, status:"approved", role:"doctor"})}> Approve</span>
                        </div>
                    )
                }
                if (record.status === "approved") {
                    return (
                        <div>
                            <span style={BlockButtonStyle} onClick={()=> changeStatus({...record, status:"blocked"})}>Block</span>
                        </div>
                    )
                }
                if (record.status === "blocked") {
                    return (
                        <div>
                            <span onClick={()=> changeStatus({...record, status:"approved"})}>Unblock</span>
                        </div>
                    )
                }
                if (record.status === "unblock") {
                    return (
                        <div>
                            <span onClick={()=> changeStatus({...record, status:"approved"})}>Block</span>
                        </div>
                    )
                }
            }
        },
    ]

    const changeStatus= async(payload) => {
        try {
            dispatch(ShowLoader(true));
            const response= await UpdateDoctor(payload);
            dispatch(ShowLoader(false));
            if (response.success) {
                message.success(response.message);
                getData();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error(error.message);
            dispatch(ShowLoader(false));
        }
    }

    useEffect(()=>{
        getData();
    },[]);
  return (
    <div>
        <Table columns={columns} dataSource={doctors} />
    </div>
  )
}

export default DoctorsList