import React from "react";
import "./talk.css";

const Talk = () => {
  return (
    <section className="sec">
      <div className="main_container talk-container">
        <div className="hp-talk-img">
          <img src="/assets/customersvg1.svg" alt="" />
        </div>
        <div className="hp-talk-div">
          <h3>Our Promises To Our Customers</h3>
          <p style={{fontSize:"1rem",lineHeight:"1.4rem",fontFamily:"Poppins, sans-serif",fontWeight:"500",maxWidth:"30rem",color:"white"}}>
          Affordable and Accessible Mental Health Support. Evidence-based Practices. Compassionate Care. Empowering Individuals to Thrive and Prioritize Their Wellbeing.
          </p>
          <span>Tell Us Your Problem</span>

          <button className="button-61" role="button">
            Let's Talk
          </button>
        </div>
      </div>
    </section>
  );
};

export default Talk;
