import { initializeApp } from "firebase/app";
import { GoogleAuthProvider,
     getAuth, 
     signInWithPopup,
     createUserWithEmailAndPassword,
     signOut } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBBvlTBxqt2aT9g8ypthDOp-8zqEayF5ZE",
  authDomain: "onlymind-4ac70.firebaseapp.com",
  projectId: "onlymind-4ac70",
  storageBucket: "onlymind-4ac70.appspot.com",
  messagingSenderId: "121596340212",
  appId: "1:121596340212:web:7db1c87cc854ec1a37fba1",
  measurementId: "G-RTTNZM7RNQ"
};

const app = initializeApp(firebaseConfig);
const firestoreDatabase = getFirestore(app);
export const auth =getAuth(app);
export const storage = getStorage(app);
export default firestoreDatabase;
