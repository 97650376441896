import React from 'react'
import DoctorForm from '../DoctorForm/DoctorForm';
import UserProfile from './UserProfile';

function EditProfile() {
    const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div>
    {user.role === "doctor" && <DoctorForm />}
    {user.role === "user" && <UserProfile />}
    </div>
  )
}

export default EditProfile