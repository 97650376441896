import styles from "./Doctordetails.module.css";
import { useLocation,useParams } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import Data from "../../components/Data";
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetDoctorById } from "../../apicalls/doctors";
import moment from 'moment/moment';
import { ShowLoader } from "../../redux/loaderSlice";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PhoneIcon from '@mui/icons-material/Phone';    
import MailIcon from '@mui/icons-material/Mail';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { BookAppointmentStore, GetDoctorAppointsOnDate } from "../../apicalls/appointments";
import useRazorpay from "react-razorpay";
import axios from "axios";


const DoctorDetails = () => {
  const [doctor, setDoctor] = useState({});
  const [readmore,setReadMore]= useState(false)
  const [consultMode, setConsultMode] =useState(""); 
  const [date, setDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [bookedSlots, setBookedSlots] = useState([]);
  const [problem, setProblem] = useState(""); 
  const { id } = useParams();
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentPage = () => {
    navigate(`/doctordetails/BookAppointment/${doctor.userId}`);
  };

  
  const [loading, setLoading] = useState(true);
  const [doctordetail, setdoctordetail] = useState({});

  /*
  ****************************************************************************************************************
    this is the function used to fetch detail of individual doctor from database and after getting details will
    show on ui
  **************************************************************************************************************
  */
  const getData = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetDoctorById(id);
      if (response.success) {
        setDoctor(response.data);
      } else {
        message.error("Incorrect URL");
      }
      dispatch(ShowLoader(false));
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  }

  const getSlotsData = () => {
    const day = moment(date).format("dddd");
    if (!doctor.days.includes(day)) {
      return <h3>Doctor is not available on {moment(date).format("DD-MM-YYYY")}</h3>
    }

    let startTime = moment(doctor.startTime, "hh:mm A");
    let endTime = moment(doctor.endTime, "hh:mm A");
    let slotDuration = 60; //in minutes
    const slots = [];
    while (startTime < endTime) {
      slots.push(startTime.format("hh:mm A"));
      startTime.add(slotDuration, "minutes");
    }
    return slots.map((slot) => {
      const isBooked = bookedSlots?.find(
        (bookedSlot) => bookedSlot.slot === slot && bookedSlot.status !=="cancelled"
      );
      return <div onClick={() => setSelectedSlot(slot)}
        style={{
          border: selectedSlot === slot ? "3px solid green" : "1px solid gray",
          backgroundColor: isBooked ? "gray" : "white",
          pointerEvents: isBooked ? "none" : "auto",
          cursor: isBooked ? "not-allowed" : "pointer",
          color: isBooked ? "white" : "black",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          fontWeight: "bold",
        }}>
        <span>
          {moment(slot, "hh:mm A").format("hh:mm A")} - {" "} {moment(slot, "hh:mm A").add(slotDuration, "minutes").format("hh:mm A")}
        </span>
      </div>
    })
  }
  const displayRazorpay = async () => {
    const { data: { key } } = await axios.get("http://www.localhost:4000/payapi/getkey")
    const { data: { order } } = await axios.post("http://localhost:4000/payapi/checkout", {
      amount: doctor.fees
    })
    const options = {
      key,
      amount: doctor.fees,
      currency: "INR",
      name: "OnlyMind",
      description: "Appointment Booking",
      image: "",
      order_id: order.id,
      handler:async function(response) {
        const payload = {
          doctorId: doctor.userId,
          userId: JSON.parse(localStorage.getItem("user")).id,
          date,
          slot: selectedSlot,
          doctorName: doctor.name,
          userName: JSON.parse(localStorage.getItem("user")).name,
          userEmail: JSON.parse(localStorage.getItem("user")).email,
          bookedOn: moment().format("DD-MM-YYYY hh:mm A"),
          consultMode: consultMode,
          problem: problem,
          status: "pending",
          order_id:order.id,
        };
          const data = {
            orderCreationId: order.id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          const result = await axios.post('http://localhost:4000/api/paymentverification',data);
          if(result.data.msg === "success") {
            payload.razorpayDetails= data;
            BookAppointmentStore(payload);
            alert("Payment Successful");
          }
      },
      prefill: {
        name: JSON.parse(localStorage.getItem("user")).name,
        email: JSON.parse(localStorage.getItem("user")).email,
        contact: JSON.parse(localStorage.getItem("user")).mobile
      },
      notes: {
        "address": "Razorpay Corporate Office"
      },
      theme: {
        "color": "rgb(126,49,208)"
      }
    };
    const razor = new window.Razorpay(options);
    razor.on('payment.failed', function (response){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
});

    razor.open();
}
  const getBookedSlots = async () => {
    try {
      dispatch(ShowLoader(true));
      const response = await GetDoctorAppointsOnDate(id, date);
      dispatch(ShowLoader(false));
      if (response.success) {
        console.log(response.data);
        setBookedSlots(response.data);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(ShowLoader(false));
    }
  }

  const handleConsultMode= (e)=>{
    setConsultMode(e.target.value);
  }
 
  useEffect(() => {
    getData();
  }, [id])
  
  useEffect(() => {
    if (date) {
      getBookedSlots();
    }
  }, [date]);



  return (
    <>
      <div className={styles.doc_pg_outer}>
      </div>
      <div className={styles.maindiv}>
        <div className={styles.Details}>

        {/* detailsfirst */}
          <div  className={styles.detailfirst} >
              <div className={styles.doc_pg_inner}>
                <img
                  className={styles.indi_docimg}
                  src="/assets/avatar.png"
                  alt=""
                />
              </div>
              <div style={{marginTop:"4rem"}}>
              <h2 className={styles.name}>{doctor.name}</h2>
                <div className={styles.icondiv} >
                <span className={styles.icon} >
                <BusinessCenterIcon/>
                </span>
                <p className={styles.designation}>{doctor.speciality}</p>
              </div>
              <div className={styles.icondiv} >
                <span className={styles.icon} >
                <SchoolOutlinedIcon/>
                </span>
                <p>{doctor.qualification}</p>
        
              </div>
              <div className={styles.icondiv} >
                <span className={styles.icon} >
                <TranslateOutlinedIcon/>
                </span>
                <p>{doctor.languagespoken}</p>
                
              </div>
              
              </div>
    
          </div>

          {/* detailcenter */}
          <div className={styles.detailsecond} >
           <h3>Personal Details</h3>
            <div className={styles.indiItemPersonal}>
              <div className={styles.detailicon} >
                <PhoneIcon className={styles.perdeticon}/>
                <p>{doctor.phoneNumber}</p>
              </div>
            </div>
            <div className={styles.indiItemPersonal}>
              <div className={styles.detailicon} >
                <MailIcon className={styles.perdeticon}/>
                <p>{doctor.email}</p>
              </div>
            </div>
            <div className={styles.indiItemPersonal}>
              <div className={styles.detailicon} >
                <CurrencyRupeeIcon className={styles.perdeticon}/>
                <p>{doctor.fees}</p>
              </div>
            </div>
          </div>

          {/* detailthird */}
          <div className={styles.detailthird} >
          <div className={styles.specialization}>
            <div className={styles.icondiv} style={{marginBottom:"10px"}} >
              <span className={styles.icon} >
                <StarOutlineOutlinedIcon />
              </span>
              <h3>Specifications</h3>
            </div>
            <ul className={styles.doc_speciality}>
              {/* {
               ( doctor.specializations).map(item=>{
                  return <li className={styles.indi_speciality}>
                    {item}
                  </li>
                })
              } */}

              <li>
                <span className={styles.indi_speciality}> Stress</span>
              </li>
              <li>
                <span className={styles.indi_speciality}> Anxiety</span>
              </li>
              <li>
                <span className={styles.indi_speciality}> Depression</span>
              </li>
              <li>
                <span className={styles.indi_speciality}> Relationship Issues</span>
              </li>
              <li>
                <span className={styles.indi_speciality}> Self Confidence</span>
              </li>
              <li>
                <span className={styles.indi_speciality}> Anger</span>
              </li>
              <li>
                <span className={styles.indi_speciality}>Sleep Issue</span>
              </li>
            </ul>
          </div>
          </div>
        </div>

        <div className={styles.appointment}>
          <div className={styles.appointfirst} >
          <div className={styles.daysDiv}>
          <span className={styles.headingappointment}>Book Appointment</span>
            <div>
              <span style={{ fontWeight: "bold" }}> Days Available:</span> &nbsp; {doctor.days ? doctor.days.join(', ') : ''}
            </div>
            <div className={styles.DatePicker} >
              <input type='date' value={date} onChange={(e) => setDate(e.target.value)} min={moment().format("YYYY-MM-DD")} />
            </div>
            <div className={styles.consultation} >
              <p style={{ fontWeight: "bold", marginBottom: "0.6rem" }}>Consultation Mode</p>
              <input type="radio" id="physical" name="media" value="physical" onChange={handleConsultMode}/>
              <label for="physical" style={{ fontWeight: "normal", marginLeft: "10px" }}>Physical</label>
              <input type="radio" id="video" name="media" value="video" onChange={handleConsultMode}/>
              <label for="video" style={{ fontWeight: "normal", marginLeft: "10px" }}>Video</label>
            </div>
          </div>
          <div className={styles.center} >
             <textarea
                placeholder='Enter your problem here'
                rows="10" col="20" className={styles.problemarea}   onChange={(e)=> setProblem(e.target.value)}
              ></textarea>
          </div>
          </div>
          <div className={styles.appointsecond} >
            <div className={styles.timeselectContainer}>
              <p className={styles.slotbtn} >
                {date ? "Select Slot" : "Choose Date First"}
              </p>
              <div className={styles.timeContainer} >
                {date && getSlotsData()}
              </div>
            </div>
            {selectedSlot &&
            (<div>
              
              <div className={styles.bottomleft}>
                <button className={styles.bookbtn} onClick={displayRazorpay}>Book Appointment</button>
                <button className={styles.btnOutline} onClick={() => navigate("/doctordetails")}>
                  Cancel
                </button><br></br>
              </div>
            </div>)}
          </div>
        </div>
             </div>
    </>
  );
};

export default DoctorDetails;
