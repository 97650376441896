
import React from "react";
import "./footer.css";
import data from "./data";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-div1">
          <img src="/assets/footersvg.svg" alt="" width={120} />
          <p>
          Welcome to Onlymind, where we prioritize your emotional wellbeing and balance.
          </p>
        </div>
        <div className="footer-div2">
          <h4>Social Links</h4>
          <div className="footer__socials">
            {data.map((item) => (
              <Link
                key={item.id}
                to={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </Link>
            ))}
          </div>
        </div>

        <div className="footer-div3">
          <div className="footer-div31">
            <ul className="footer__menu">
              <li className="foot-item">
                <Link to="/">Home</Link>
              </li>
              <div> |</div>
              <li className="foot-item">
                <Link to="/services">Our Services</Link>
              </li>
              <div> |</div>
              <li className="foot-item">
                <Link to="/about">About Us</Link>
              </li>
              <div> |</div>
              <li className="foot-item">
                <Link to="/contact">Contact Us</Link>
              </li>
              <div> |</div>
            </ul>
          </div>
          <div className="footer__copyright">
            <small>2023 OnlyMind &copy; All Rights Reserved</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
