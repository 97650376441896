import './App.css';
import { useSelector } from 'react-redux';
import AboutUs from './Pages/About/AboutUs';
import DoctorDetails from './Pages/doctordetails/DoctorDetails';
import Home from './Pages/homePage/Home';
import Footer from './Pages/homePage/footer/Footer';
import Login from './Pages/login/Login';
import Register from './Pages/register/Register';
import UserProfile from './Pages/userProfile/UserProfile';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './Pages/Contact/Contact';
import ProtectedRoutes from './components/ProtectedRoutes';
import Spinner from './components/Spinner';
import Services from './Pages/services/Services';
import DoctorForm from './Pages/DoctorForm/DoctorForm';
import Admin from './Pages/Admin/Admin';
import DoctorsProfile from './Pages/DoctorsProfile/DoctorsProfile';
import BookAppointment from './Pages/BookAppointment/BookAppointment';
import Appointments from './Pages/userProfile/Appointments';
import EditProfile from './Pages/userProfile/EditProfile';
import Notifications from './Pages/Notifications/Notifications';
import MeetingRoom from './Pages/MeetingRoom/MeetingRoom';
import ScrollTop from './components/ScrollTop';

function App() {
  const { loading } = useSelector(state => state.loader)
  
  return (
    <>
      {loading && <Spinner />}
    <Router>
    <ScrollTop/>
      <Navbar  />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/about' element={<AboutUs/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login/>} />
        <Route path='/applyfordoctor' element={<ProtectedRoutes><DoctorForm/></ProtectedRoutes>}/>
        <Route path='/doctordetails/BookAppointment/:id' element={<ProtectedRoutes><BookAppointment/></ProtectedRoutes>}/>
        <Route path='/admin' element={<ProtectedRoutes><Admin/></ProtectedRoutes>} />
        <Route path='/profile' element={<ProtectedRoutes><UserProfile/></ProtectedRoutes>} />
        <Route path='/notifications' element={<ProtectedRoutes><Notifications/></ProtectedRoutes>} />
        <Route path='/editprofile' element={<ProtectedRoutes><EditProfile/></ProtectedRoutes>} />
        <Route path='/myappointments' element={<ProtectedRoutes><Appointments/></ProtectedRoutes>} />
        <Route path='/meeting/:roomId' element={<ProtectedRoutes><MeetingRoom/></ProtectedRoutes>} />
        <Route path='/doctordetails' element={<ProtectedRoutes><DoctorsProfile/></ProtectedRoutes>} />
        <Route path='/doctordetails/:id' element={<ProtectedRoutes><DoctorDetails/></ProtectedRoutes>} />
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
