import React from "react";
import "./team.css";

const Team = () => {
  return (
    <section>
      <div id="team">
        <div className="main_container team_container">
          <div className="hp_team_div1">
            <div className="hp_team_div11">
              <h2>How We Help You</h2>
              <p style={{fontSize:"1rem",lineHeight:"1.4rem",fontFamily:"Poppins, sans-serif",fontWeight:"500",maxWidth:"30rem",color:"gray"}} >  
              
                Onlymind is committed to improving mental health and wellbeing through accessible, affordable,
              and personalized care. Our goal is to empower individuals and communities to take charge of their
              mental health journey and live fulfilling lives. With a focus on evidence-based practices and
              compassionate care, we strive to make mental health support accessible to all.
              </p>
              
            </div>
            <div className="hp_team_div12">
            <img src="\assets\homepg\hpHelpImg.svg" alt="" />
            </div>
          </div>

          <div className="hp_team_div1 hp_team_flex">
          <div className="hp_team_div12">
            <img src="\assets\homepg\hpTeamImg.svg" alt="" />
            </div>
            <div className="hp_team_div11">
              <h2>Our Team</h2>
              <p style={{fontSize:"1rem",lineHeight:"1.4rem",fontFamily:"Poppins, sans-serif",fontWeight:"500",maxWidth:"30rem",color:"gray"}}>
              At Onlymind, our expert team empowers mental health and wellbeing. With accessibility, affordability, and personalized care, we support your journey. Through evidence-based practices and compassion, we foster fulfillment. Trust our experience for improved mental health.
              </p>
             
            </div>

          </div>


        </div>

        
      </div>
    </section>
  );
};

export default Team;
