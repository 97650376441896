import React, { useCallback, useEffect, useState } from 'react'
import { GetDoctorAppointments, GetUserAppointments, updateAppointmentStatus } from '../../apicalls/appointments';
import { Table, message, Tabs } from 'antd'
import './Appointments.css';
import { ShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import VideoCallIcon from "@mui/icons-material/VideoCall";
import styles from "../doctordetails/Doctordetails.module.css";
import { useNavigate } from 'react-router-dom';


function Appointments() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [value,setValue] = useState(null)
    const user = JSON.parse(localStorage.getItem("user"));
    const getData = async () => {
        try {
            dispatch(ShowLoader(true));
            if (user.role === "doctor") {
                const response = await GetDoctorAppointments(user.id);
                if (response.success) {
                    setAppointments(response.data);
                    console.log(response.data);
                }
            } else {
                const response = await GetUserAppointments(user.id)
                if (response.success) {
                    setAppointments(response.data);
                }
            }
            dispatch(ShowLoader(false));
        } catch (error) {
            dispatch(ShowLoader(false));
            message.error(error.message);
        }
    };

    const onUpdate = async (id, status) => {
        const statusUpdateOn = moment().format("DD-MM-YYYY hh:mm A")
        try {
            dispatch(ShowLoader(true));
            const response = await updateAppointmentStatus(id, status, statusUpdateOn);
            if (response.success) {
                message.success(response.message);
                getData();
            } else {
                message.error(response.message);
            }
            dispatch(ShowLoader(false));
        } catch (error) {
            message.error(error.message);
            dispatch(ShowLoader(false));
        }
    };

    const handleVideo=(rec) => {
        setValue(rec.id);
        navigate(`/meeting/${rec.id}`)
    };

    const ApproveButtonStyle = {
        backgroundColor: 'green',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '10px',
        width: '85px',
        height: '40px',
        maxWidth: '100px',
        marginLeft: '10px',
        cursor: 'pointer',
        fontWeight: 'bold'
    }
    const RejectButtonStyle = {
        backgroundColor: 'red',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '10px',
        width: '70px',
        height: '40px',
        maxWidth: '100px',
        cursor: 'pointer',
        fontWeight: 'bold'
    }


    const columns = [
        {
            title: "Date",
            dataIndex: "date"
        },
        {
            title: "Doctor",
            dataIndex: "doctorName"
        },
        {
            title: "Patient",
            dataIndex: "userName"
        },
        {
            title: "Booked On",
            dataIndex: "bookedOn"
        },
        {
            title: "Meeting Time",
            dataIndex: "slot"
        },
        {
            title: "Consultation Mode",
            dataIndex: "consultMode"
        },
        {
            title: "Problem",
            dataIndex: "problem"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (text, record) => {
                if (record.status === "pending") {
                    if (user.role === "doctor") {
                        return (
                            <div style={{ display: 'flex' }}>
                                <span style={RejectButtonStyle}
                                    onClick={() => onUpdate(record.id, "rejected")}>Reject</span>
                                <span style={ApproveButtonStyle}
                                    onClick={() => onUpdate(record.id, "approved")}> Approve</span>
                            </div>
                        )
                    }
                }else if(record.status === "approved") {
                    
                    return <div className={styles.icondiv} onClick={()=>handleVideo(record)}>
                    <span className={styles.icon} >
                    <VideoCallIcon /></span>
                    </div>
                } else {
                    return <div>Rejected!</div>
                }
            }
        },
    ]

    useEffect(() => {
        getData();
    }, [])
    return (
        <div className='profile-style'>
            <Tabs>
                <Tabs.TabPane tab="Appointments" key="1">
                    <div className="appointments-table">
                        <Table columns={columns} dataSource={appointments || []} />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default Appointments