import React from 'react'
import styles from './DoctorsProfile.module.css'
import DoctorCard from '../../components/DoctorCard/DoctorCard'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { message } from 'antd'
import { ShowLoader } from '../../redux/loaderSlice'
import { GetAllApprovedDocs } from '../../apicalls/doctors'
import { useNavigate } from 'react-router-dom'
import Header from '../homePage/header/Header'
const DoctorsProfile = () => {
    const navigate= useNavigate();
    const [doctors,setDoctors]= useState([])
    const dispatch = useDispatch();

    const getData = async ()=>{
        try {
            dispatch(ShowLoader(true))
            const response = await GetAllApprovedDocs();
            if(response.success){
                setDoctors(response.data)
            }else{
                message.error(response.message)
            }
            dispatch(ShowLoader(false))
            
        } catch (error) {
            message.error(error.message);
            dispatch(ShowLoader(false));
        }
    }


    useEffect(()=>{
        getData();
    },[])

  return (
    <div className={styles.doctorsprofilecontainer}  >
   
     <Header title={"Our Doctors"} descr={"Introducing Our Doctors: Experienced, compassionate professionals dedicated to your well-being. With diverse specialties and a patient-centered approach, our team provides exceptional care. Trust our expertise and personalized treatment plans for a healthier future. Visit us today!"} imgurl={"../assets/DoctorsHome1.svg"} btntitle={"Get Started"} />
       <h2 className={styles.heading} >Chose Any One </h2>
     <div className={styles.innerContainer} >
        {doctors.map((doctor)=>{
            return (<div onClick={()=>navigate(`/doctordetails/${doctor.userId}`)}>
            <DoctorCard name={doctor.name} speciality={doctor.speciality} experience={doctor.experience} email={doctor.email} phone={doctor.phoneNumber} />
        </div>)})}
        
     </div>
    </div>
  )
}

export default DoctorsProfile
