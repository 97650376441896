import React from "react";
import "./about.css";
const About = () => {
  return (
    <div id="about">
      <div className="main_container about_container">
        <div className="cards">
          <div className="card">
            <div className="content">
              <span className="about-title">Our Goals</span>
              <p className="desc">
              Personalized Mental Health Support: Achieve Wellness Goals. Take Control Today. Trust Us for Quality, Security, and Empowerment. Thrive on Your Path to Wellbeing.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <span className="about-title">Our mission</span>
              <p className="desc">
              Affordable, Personalized Mental Health Support: Evidence-Based Guided Meditations and Counseling. Break Barriers, Prioritize Wellbeing. Overcome Anxiety, Depression, Stress. 
              </p>
            </div>
          </div>
          <div className="card">
            <div className="content">
              <span className="about-title">Our Services</span>
              <p className="desc">
              Specialized physical consultancy for doctors and patients. Holistic wellness approach. Yoga and meditation sessions offered. Transformative guidance for optimal health.
              </p>
            </div>
          </div>
        </div>

        <h2>Our Company</h2>
        <div className="about-des">
          <p>
            Our mission is to provide accessible and effective resources to help
            you achieve
            <br className=" about_des_br" />
            inner peace and harmony. We believe in the power of mindfulness,
            self-care, <br className=" about_des_br" />
            and therapy to improve emotional wellbeing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;





