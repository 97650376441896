import { collection, addDoc, getDocs, where, query, updateDoc, doc } from 'firebase/firestore';
import firestoreDatabase from '../firebaseConfig';

export const BookAppointmentStore = async (payload) => {
    try {
        await addDoc(collection(firestoreDatabase, 'appointments'), payload);        
        return {
            success: true,
            message: "Appointment booked successfully",
        };
    } catch (error) {
        return {
            success: false,
            message: error.message
        };
    }
}

export const GetDoctorAppointsOnDate = async (doctorId, date) => {
    try {
        const querySnapshot = await getDocs(
            query(
                collection(firestoreDatabase, "appointments"),
                where("doctorId", "==", doctorId),
                where("date", "==", date)
            )
        );
        const data = [];
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
        return {
            success: true,
            data
        };
    } catch (error) {
        return {
            success: false,
            message: error.message,
        };
    }
}

export const GetDoctorAppointments = async (doctorId) => {
    try {
        const querySnapshot = await getDocs(
            query(
                collection(firestoreDatabase, "appointments"),
                where("doctorId", "==", doctorId),
            )
        );
        const data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id,
            })
        });
        return {
            success: true,
            data
        };
    } catch (error) {
        return {
            success: false,
            message: error.message,
        };
    }
}

export const GetUserAppointments = async (userId) => {
    try {
        const querySnapshot = await getDocs(
            query(
                collection(firestoreDatabase, "appointments"),
                where("userId", "==", userId),
            )
        );
        const data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id})
        });
        return {
            success: true,
            data
        };
    } catch (error) {
        return {
            success: false,
            message: error.message,
        };
    }
}

export const updateAppointmentStatus = async (id, status,updateTime) => {
    try {
        await updateDoc(doc(firestoreDatabase, "appointments", id), {
            status,
        })
        await updateDoc(doc(firestoreDatabase, "appointments", id), {
            updateTime,
        })
        return {
            success: true,
            message: "Appointment status updated",
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    };
}

export const GetNotifications = async (userId,role) => {
    try {
        let querySnapshot= null;
        if(role==="user"){
             querySnapshot = await getDocs(
                query(
                    collection(firestoreDatabase, "appointments"),
                    where("userId", "==", userId),
                )
            );
        } else {
             querySnapshot = await getDocs(
                query(
                    collection(firestoreDatabase, "appointments"),
                    where("doctorId", "==", userId),
                )
            );
        }
        
        const data = [];
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
        return {
            success: true,
            data
        };
    } catch (error) {
        return {
            success: false,
            message: error.message,
        };
    }
}
