import { Spin } from 'antd';
import "./Spinner.css";

function Spinner() {
  return (
    <div className='spinner-parent'>
        <Spin size='large'/>
    </div>
  )
}

export default Spinner