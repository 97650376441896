import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './Register.module.css';
import { CreateUser } from '../../apicalls/users';
import 'firebase/firestore';
import { ShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';

const Register = () => {
  const dispatch = useDispatch();
  const id = 1;
  const [email, setEmail] = useState("")
  const [gender, setGender] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("")
  const [values, setValues] = useState({})
  const navigate = useNavigate();

  const handleClick = () => {
    setValues({
      email,
      name,
      password,
      mobile,
      gender,
      id
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(ShowLoader(true));
      const response = await CreateUser({ ...values, role: "user", });
      dispatch(ShowLoader(false));
      if (response.success) {
        message.success(response.message);
        navigate("/login");
      } else {
        throw message.error(response.message);
      }
      setEmail('');
      setName('');
      setPassword('');
      setMobile('');
    } catch (error) {
      dispatch(ShowLoader(false));
      setError(error.message);
    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      navigate("/profile");
    }
  }, []);

  return (
    <div className={styles.form_container}>
      <h2>Register</h2>
      {error && <p style={{ color: "white", width: "fit-content", height: "30px", display: "flex", alignItems: "center", padding: "5px 10px", backgroundColor: "red", borderRadius: "10px" }}>{error}</p>}

      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={name} onChange={e => setName(e.target.value)} required />

        </div>
        <div>
          <div className={styles.genderdiv}>
            <label htmlFor="gender"> <p className={styles.title}>Select Your Gender</p></label>
            <select id="gender" value={gender} onChange={e => setGender(e.target.value)}>
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="mobile">Mobile Number:</label>
          <input type="tel" id="mobile" name="mobile" minLength={10} maxLength={10} value={mobile} onChange={e => setMobile(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required />

        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="password">Confirm Password:</label>
          <input type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required />
        </div>
        <button type="submit" onClick={handleClick}>Register</button>
      </form>
    </div>
  )
}

export default Register
