
import React, { useState } from "react";

import { Link } from 'react-router-dom'
import './AboutService.css'
import ComingSoon from './../../comingSoon/ComingSoon';


const AboutService = () => {
  
  const [isComingSoonOpen, setComingSoonOpen] = useState(false);
  
  const openComingSoonModal = () => {
    setComingSoonOpen(true);
  };

  const closeComingSoonModal = () => {
    setComingSoonOpen(false);
  };



  return (
    <section className="service-sec">
    <div className="main_container service-container">
      <div className="service-div1">
        <div className="service-mission-txt">
          <h1>Our Specialists</h1>
          <ul>
            <li><span>Psychologist</span></li>
            <li><span>Psychiatrist</span></li>
            <li><span>Physician</span></li>
            <li><span>Psychotherapist</span></li>
            <li><span>Disorders analyst</span></li>
          </ul>
          {/* <Link to='/doctordetails'> */}
          <Link to=''>
            <button className='servicebtn'  onClick={openComingSoonModal} >Book an Appointment</button>
          </Link>
        </div>
        <div className="service-missionsvg">
          <img src="/assets/servicesimg2.svg" alt="" />
        </div>
      </div>
      <div className="service_div2">
        <div className="service-storysvg">
          <img src="/assets/servicesimg3.svg" alt="" />
        </div>
        <div className="service-story-txt">
          <h1>Contribute as a Doctor</h1>
          <p>
          Embrace the opportunity to contribute as a doctor and be part of our mission to create a positive impact in the field of healthcare. Join our team of dedicated professionals as we work together to provide exceptional medical care and improve patient outcomes. Together, we can make a difference and shape the future of healthcare. Become a part of our community today and let your expertise shine in making a meaningful contribution to the lives of patients.
          </p>
          {/* <Link to='/applyfordoctor'> */}
          <Link to=''>

            <button className='servicebtn' onClick={openComingSoonModal} style={{backgroundColor:"#A634FF",marginLeft:"-0.3rem"}} >Apply as a Doctor</button>
          </Link>
        </div>
        
      </div>
      <div className="service_div2 our-session">
        <div className="service-story-txt">
            <h1 style={{maxWidth:"400px"}}> <span style={{color:"black",marginLeft:"0px"}}>Our Yoga and Meditation Sessions:</span> Cultivate Inner Peace and Wellness</h1>
            <p >
            Discover the Transformative Power of Yoga and Meditation for Inner Peace and Wellbeing. Join our Expertly Guided Sessions to Find Balance, Relaxation, and Personal Growth. Nurture Your Mind, Body, and Soul for a Harmonious and Fulfilling Life. Experience the Benefits of Mindfulness Today!
            </p>
            {/* <Link to='/doctordetails'> */}
            <Link to=''>
                <button className='servicebtn' onClick={openComingSoonModal} style={{backgroundColor:"#A634FF",marginLeft:"-0.3rem"}} >View Sessions</button>
            </Link>
        </div>
        <div className="service-storysvg">
          <img src="/assets/servicesimg4.svg" alt="" />
        </div>
      </div>
      
      
    </div>
    {isComingSoonOpen && <ComingSoon closeModal={closeComingSoonModal} />}


  </section>
  )
}

export default AboutService
