import React from 'react'
import styles from './DoctorCard.module.css'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';

const DoctorCard = ({name,speciality,experience,email,phone}) => {
  return (
    <div className={styles.mainCardContainer} >
      <div className={styles.leftdiv}>
        <h3>{name}</h3>
        <p>{speciality}</p>
        <p>Experience: {experience}</p>
        <div className={styles.icondiv}>
            <div className={styles.innericons}>
                <EmailIcon  className={styles.icon}
                />
                <span>{email}</span>
            </div>
            <div className={styles.innericons}>
                <PhoneIcon className={styles.icon} />
                <span> {phone}</span>
            </div>
        </div>
      </div>
      <div className={styles.rightdiv}>
        <div className={styles.imgdiv} >
            <img src="../assets/avatar.png" alt="" />
        </div>
        <Link to=''>
            <button className={styles.btn} >View Details</button>
        </Link>
      </div>
    </div>
  )
}

export default DoctorCard
