import styles from './UserProfile.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { GetUserById, UpdateUser } from '../../apicalls/users';
import { ShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

const UserProfile = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [mobilenumber, setMobileNumber] = useState('');
    const [imgurl, setImgUrl] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfsR1j5c5rh3Or62MF39b1EffRT1nXnqN4BQ&usqp=CAU");
    const [gender, setGender] = useState('');
    const [formdata, setFormdata] = useState({})

    //function for user to  change profile pic
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setImgUrl(e.target.result);
        };

        reader.readAsDataURL(file);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormdata((prevData) => ({ ...prevData, [name]: value }));
    }

    //calling API to submitting data to database
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(ShowLoader(true));
            const response = await UpdateUser(formdata);
            if (response) {
                message.success(response.message);
            } else {
                message.error(response.message);
            }
            dispatch(ShowLoader(false));
            const { id, name, email } = formdata;
            const sanitizedPayload = {
                id,
                email,
                name,
                password: "****",
                role: "user"
            };

            localStorage.setItem("user", JSON.stringify(sanitizedPayload));
        } catch (error) {
            dispatch(ShowLoader(false));
            message.error(error.message);
        }
    };

    const userExists = async () => {
        try {
            dispatch(ShowLoader(true));
            const response = await GetUserById(JSON.parse(localStorage.getItem("user")).id);
            if (response.success) {
                setFormdata(response.data);
            }
            dispatch(ShowLoader(false));
        } catch (error) {
            dispatch(ShowLoader(false));
            message.error(error.message);
        }
    }

    useEffect(() => {
        userExists();
    }, [])
    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h2>Edit Profile</h2>
                <span style={{ cursor: "pointer" }}>
                    <ArrowForwardIosIcon />
                </span>
            </div>
            <div className={styles.mainsection}>
                <div className={styles.left}>
                    <h3>Profile</h3>
                    <div className={styles.profilepic}>
                        <div className={styles.userimg}>
                            <img src={imgurl} alt="userimg" />
                        </div>
                        <label htmlFor="fileInput">
                            <span className={styles.editicon}>
                                <EditIcon />
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </span>
                        </label>

                    </div>
                    <div className={styles.userdetail}>
                        <h1 className={styles.username}>{name}</h1>
                        <p className={styles.about}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, voluptatum.</p>
                    </div>

                </div>
                <form onSubmit={handleSubmit} className={styles.center}>
                    <div className={styles.name} >
                        <div>
                            <label htmlFor="name" ><p className={styles.title}>Name</p></label>
                            <input type="text" id="name" name="name" placeholder='Mehar' maxLength={16} value={formdata.name} onChange={handleInputChange} className={styles.inputfld} style={{ width: "100%" }} />
                        </div>
                    </div>

                    <div>
                        <div className={styles.genderdiv}>
                            <label htmlFor="gender"> <p className={styles.title}>Select Your Gender</p></label>
                            <select id="gender" name="gender" value={formdata.gender} onChange={handleInputChange}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="mobilenumber" ><p className={styles.title}>Mobile Number</p></label>
                        <input type="tel" id="mobilenumber" name="mobile" value={formdata.mobile} onChange={handleInputChange} placeholder='' minLength={10} maxLength={10} className={styles.inputfld} />
                    </div>

                    <div>
                        <label htmlFor="email" ><p className={styles.title}>Email address</p></label>
                        <input type="email" id="email" name="email" value={formdata.email} onChange={handleInputChange} placeholder='mehar@gmail.com' className={styles.inputfld} />
                    </div>
                    <button className={styles.btn}>Update</button>
                </form>
            </div>
        </div>
    )
}

export default UserProfile
