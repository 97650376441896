import React from "react";
import "./mission.css";
import { AiFillCheckCircle } from "react-icons/ai";

const Mission = () => {
  return (
    <section className="sec3">
      <div className="main_container mission_container">
        <div className="txt2">
          <h1 className="mission-title">Why Choose </h1>
          <img className="misson_logo" src="/assets/logo.png" alt="logo" />
        </div>
        <div className="mission_div1">
          <div className="mission_div11">
            <p style={{fontFamily:"Poppins, sans-serif"}}>
            At Onlymind, we believe in empowering our users through education, inclusivity, safety, and
collaboration.
            </p>
            <ul className="mission_ul">
              <li>
                <AiFillCheckCircle style={{color:"#7F39FB"}}/>
                <span>Accessibility</span>
              </li>
              <li>
                <AiFillCheckCircle style={{color:"#7F39FB"}}/>
                <span>Empowerment</span>
              </li>
              <li>
                <AiFillCheckCircle style={{color:"#7F39FB"}}/>
                <span>Privacy and security</span>
              </li>
              <li>
                <AiFillCheckCircle style={{color:"#7F39FB"}}/>
                <span>Personalization</span>
              </li>
              <li>
                <AiFillCheckCircle style={{color:"#7F39FB"}}/>
                <span>Quality</span>
              </li>
            </ul>
          </div>
          <div className="mission_div12">
            <img src="\assets\homepg\whyimg.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
