import React from 'react'
import { useParams } from 'react-router-dom'
import {ZegoUIKitPrebuilt} from '@zegocloud/zego-uikit-prebuilt'
import axios from 'axios';

const MeetingRoom = () => {
  const { roomId } = useParams();
  const myMeeting = async (element)=>{
    const { data: { appID,serverSecret } } = await axios.get("http://www.localhost:4000/video/getkey")
    const user= JSON.parse(localStorage.getItem('user'));
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(parseInt(appID), serverSecret, roomId, user.id, user.name);
    const zc = ZegoUIKitPrebuilt.create(kitToken);
    zc.joinRoom({
        container: element,
        sharedLinks: [
            {
                name: 'Copy Link',
                url: `http://localhost:3000/meeting/${roomId}`,
            },
        ],
        scenario: {
            mode: ZegoUIKitPrebuilt.OneONoneCall,
        }
    })
  }
  return (
    <div >
        <div ref={myMeeting} />
    </div>
  )
}

export default MeetingRoom