import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { LoginUser } from '../../apicalls/users';
import './Login.scss'
import { ShowLoader } from '../../redux/loaderSlice';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [values, setValues] = useState({})
  const navigate = useNavigate();

  const handleClick = () => {
    setValues({
      email,
      password,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(ShowLoader(true));
      const response = await LoginUser(values);
      dispatch(ShowLoader(false));
      if (response.success) {
        message.success(response.message);
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...response.data,
            password: "****",
          })
        )
        if (response.data.role === "admin") {
          navigate("/admin")
        } else {
          navigate("/doctordetails");
        }
      } else {
        message.error(response.message);
      }
      setEmail('');
      setPassword('');
    } catch (error) {
      dispatch(ShowLoader(false));
      message.error(error.message);
    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      navigate("/doctordetails");
    }
  }, []);
  return (
    <div className='login'>
      <div className="Logincard">
        <div className="left">
          <h1>Hello User.</h1>
          <p>Welcome to OnlyMind. One of the best consultant platform for you.</p>
          <span>Don't you have an account?</span>

          <Link to='/register'>
            <button>Register</button>
          </Link>

        </div>
        <div className="right">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <input type="text" name="email" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
            <input type="password" name="password" placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
            <button type="submit" onClick={handleClick}>Login</button>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Login
