import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ShowLoader } from '../../redux/loaderSlice';
import { Table, message } from 'antd';
import { GetAllUsers } from '../../apicalls/users';

function UsersList() {
    const [doctors, setDoctors]= useState([]);
    const dispatch= useDispatch();
    const getData= async() => {
        try {
            dispatch(ShowLoader(true));
            const response= await GetAllUsers();
            dispatch(ShowLoader(false));
            if (response.success) {
                setDoctors(response.data);
            } else {
                message.error(response.message)
            }
        } catch (error) {
            dispatch(ShowLoader(false));
            message.error(error.message);
        }
    }

    const columns= [
        {
            title: "UserId",
            dataIndex: "id"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Phone",
            dataIndex: "mobile"
        },
    ]

    useEffect(()=>{
        getData();
    },[]);
  return (
    <div>
        <Table columns={columns} dataSource={doctors} />
    </div>
  )
}

export default UsersList