import { collection, addDoc, getDocs, query, where, setDoc, doc, updateDoc } from 'firebase/firestore';
import firestoreDatabase from '../firebaseConfig';

export const AddDoctor = async (payload) => {
  try {
    const usersRef = collection(firestoreDatabase, 'doctors');
    await addDoc(usersRef, payload);
    return {
      success: true,
      message: "Added successfully, Please wait for approval",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const GetDoctorById = async (id) => {
  try {
    const usersRef = collection(firestoreDatabase, 'doctors');
    const qry = query(usersRef, where("userId", "==", id));
    const querySnapshot = await getDocs(qry);
    const doctor = querySnapshot.docs[0].data();
    if (querySnapshot.size > 0) {
      return {
        success: true,
        message: "you've already applied",
        data: doctor
      }
    }
    return {
      success: false,
      message: "Doctor account not applied",
    };
  } catch (error) {
    console.log("catch:", error.message);
    return {
      success: false,
      message: error.message,
    };
  }
};

export const GetAllDoctors = async () => {
  try {
    const doctors = await getDocs(collection(firestoreDatabase, "doctors"));
    return {
      success: true,
      data: doctors.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      })
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  };
}

export const UpdateDoctor = async (payload) => {
  try {
    await setDoc(doc(firestoreDatabase, "doctors", payload.id), payload);
    const docRef = doc(firestoreDatabase, 'users', payload.userId);
    // Perform the update on the 'role' field
    await updateDoc(docRef, { name: payload.name});
    await updateDoc(docRef, { mobile:payload.phoneNumber });
    await updateDoc(docRef, { email:payload.email});
    await updateDoc(docRef, { role: 'doctor' });

    return {
      success: true,
      message: "Doctor updated successfully",
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  };
}

export const GetAllApprovedDocs = async () => {
  try {
    const usersRef = collection(firestoreDatabase, 'doctors');
    const qry = query(usersRef, where("status", "==", "approved"));
    const userSnapshot = await getDocs(qry);
    return {
      success: true,
      data: userSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
        };
      })
    };
  } catch (error) {
    return {
      success: true,
      message: "Try Again",
    };
  }
}
