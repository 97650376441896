import {AiOutlineInstagram} from 'react-icons/ai'
import {BsMeta} from 'react-icons/bs'
import {BsTelegram} from 'react-icons/bs'
import {BsWhatsapp} from 'react-icons/bs'
import {AiOutlineLinkedin} from 'react-icons/ai'



const data = [
    {id: 1, link: 'https://www.linkedin.com/company/prescientai-infotech/', icon: <AiOutlineLinkedin size={20}/> },
    {id: 2, link: '', icon: <AiOutlineInstagram size={20}/>},
    {id: 3, link: '', icon: <BsWhatsapp size={20}/>},
    {id: 4, link: '', icon: <BsTelegram size={20}/>},

]

export default data